import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { downloadFromS3 } from '../utils/s3Util';
import { sendTemplateMessage } from '../api/whatsappApi';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const sendMessage = (row) => {
    console.log('Edit button clicked for row ID:', row);
};

const downloadResume = (row) => {
  const key = row?.metadata?.key ?? null;
  const bucket = row?.metadata?.bucket ?? null;
  if(!key || !bucket){
    console.log(`Either key or bucket value is invalid for candidate id: ${row?.id}`)
    return false;
  }
  downloadFromS3(key, bucket);
}


export default function CandidateSearchList({rows}) {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const maskPhoneNumber = (number) => {
    return  "********" + number.slice(-2);
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'masked_phone_number',
      headerName: 'Phone Number',
      width: 150,
      editable: true,
      renderCell: (params) => (
        <p>{maskPhoneNumber(params.row.phone_number)}</p>
      )
    },
    {
      field: 'metadata',
      headerName: 'Resume',
      // type: 'number',
      width: 110,
      editable: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => downloadResume(params.row)}>
         <CloudDownloadIcon />
        </IconButton>
      )
    },
    {
      field: 'sendJobMessage',
      headerName: 'Send Message',
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      width: 160,
      // valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleOpen(params.row)}>
          <WhatsAppIcon />
        </IconButton>
      )
    },
  ];

  const handleOpen = (candidate) => {
    setOpen(true);
    setSelectedCandidate(candidate);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCandidate(null);
  };

  const sendMessage = async () => {
    console.log('Sending message:', message, selectedCandidate);
    if(message && message.length>0){
      let payload = {
        "phone_number": selectedCandidate.phone_number,
        "template_name": "agent_custom_message",
        "template_body_params": [message],
        "template_image_headers":["https://jp-cand-resume.s3.ap-south-1.amazonaws.com/logo.png?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDsaCmFwLXNvdXRoLTEiRzBFAiA0I3Ifr0Vk3Spwekchr1N1lL8xbj4A4sexYU1UgW4XHgIhAN02jMRll3JX4KpBdmKJPJ8T2%2FQiUKjLz4bTWwKORkKQKuQCCGQQARoMMDA5NTk0MDQ5OTYzIgx0MslQudC%2ByI44wYoqwQJwUgG63kdxyC3DoEFT%2Bf9p%2BiKyh7tMjaJVmC3RT41OkY8ch0IrqbQFhE4%2F5zqojoIl7WpkFSigLWrQCoBEbVzPZTIhK%2BJX0MOsZHumPl1oBu%2B1vWMoSoMGq4Yu52b%2FYeEHRSvrcHVJ%2FP94Pad6O6InGk7E57eLps0m3lXk0vbJA%2BZGAh8A%2FXh0F2ecplxj2MOQGpKMOjs6VLZoafxKg6aaszGtF16L4mWKdPnypWGn5b9MKWcYwX8dYIphtB4g7f9%2BUnt7PYqIdUYG6KCclKhS2u%2Fag9CB1lRySsvnrxN6VjkJkPmse37Jy5SM98n%2FAugAh%2FLHNdcPWpZMbSmj48jeoOv2VPYHwTWrhg3dI8PRean1PWkpteJGIbKnnqnuWV%2FDIBOKqgN49Rqjy4Cs3FLIZDfabogu%2FvAKUcEBEirgSIMwiqyxsAY6swKr34bacn8lFBBHGgiP%2FV7i%2BXY%2BWIae4o3GuTzdiaIoFaRZmn3%2BSnzqtJDFoLjPhDg8X8NIismkLsy%2BC3WIENZ2zwWdERO8%2B4lHih%2FE3eDZk1ZZmUsASFnC%2FYcDUfXy38QfsrF03bhUB6mN7KPCv2%2BELNFAUcxfAwdwJ1f6lD3th6LdXIIpcODRvSNRP%2FrNvQChRdDExeT5nggDYLSqnjPe3qHROF8I46I9W4V7Oyjn74m76HYcb%2B8t25kTAj8WayjMWKrB3r%2Bf4lZ2pnMsLQ7Nb3ypDYH8fHe6xDLtUVoTTiZpr12yBLQ9JRMOz5oJExYF18t7N0VLf5f1ZgDdMTkst5SG6YJcKaYd08Mx5MRrKoiSqP%2FC5qwAomX5EQU1It3R1TsZPM1KY%2FHVFKgCXTjUp%2Bb8&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240402T190217Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAQEO6ZSWV4POF3RPD%2F20240402%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=d80d7b7bb6b2e38bb97b34d2e9e81090bf197520258b3da5b030c600b90c9cdb"]
      }
      await sendTemplateMessage(payload);
    }
    handleClose();
  };

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection); 
    console.log(selection);
  }
  
  const handleExport = () => {
    
    // filter needed information 
    const data = rows.map(row => {
      return {
        Id: row.id,
        CandidateName: row.name,
        WhatsappNumber: row.phone_number
      }
    })
    const filteredRows = data.filter(row => selectedRows.includes(row.Id));
    const headers = Object.keys(filteredRows[0]);
    const csvString = [
      headers.join(","),
      ...filteredRows.map(obj => Object.values(obj).join(","))
    ].join("\n");

    // Create a Blob object with the CSV content
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // Create a hidden anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv"; // Set desired filename

    // Simulate a click to trigger download
    link.click();
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          // pageSizeOptions={[10]}
          checkboxSelection
          // disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          // selectionModel={selectionModel}
        />
      </Box>
      {selectedRows.length > 0 && (
          <Button 
            style={{ marginTop: '1em' }} 
            variant="contained" 
            color="primary"
            onClick={handleExport}
          >
            Export
          </Button>
       )}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={sendMessage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
}
