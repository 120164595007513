import React from 'react';
import './styles.css';

import ProductSvgSection from './product_svg_section';
import ProductNavSection from './product_nav_section';
import ProductSection from './product_section';
import FooterSection from './footer_section';
import ProductTopSection from './product_top_section';
import HeroSection from './hero';
import Features from './features';
import HeroLeft from './hero_left';
import Header from './header';

const Container = () => {


    return (
        <>
            {/* <ProductTopSection/> */}
            
            <Header/>
            {/* <ProductNavSection/> */}
            <HeroSection/>
            <Features/>
            <HeroLeft/>
            {/* <Pricing/> */}
            <FooterSection/>
        </>
    )
}
export default Container;