import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import AWS from 'aws-sdk';

const S3Uploader = ({ uploadText, onFileChange, onFileUpload, bucket, s3FileKey }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    onFileChange(event.target.files[0]);
  };

  const handleUpload = (e) => {
    // Configure AWS with your access key and secret key
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS
    });

    // Create S3 instance
    const s3 = new AWS.S3();

    // Define parameters for the upload
    const params = {
      Bucket: bucket,
      Key: s3FileKey,
      Body: file
    //   ACL: 'public-read' // Optionally set the access control list
    };

    // Upload file to S3
    s3.putObject(params, (err, data) => {
      if (err) {
        console.error('Error uploading file:', err);
        onFileUpload({
          status: "failed"
        })
      } else {
        console.log('File uploaded successfully:', data);
        onFileUpload({
          status:"success",
          data
        })
      }
    });
  };

  return (
    <>
     <TextField
          // variant="outlined"
          type="file"
          onChange={handleFileChange}
          // InputLabelProps={{ shrink: true }}
          fullWidth
      />
      <Button 
        variant="contained" 
        onClick={handleUpload} 
        disabled={!file}
        sx={{ marginTop: '16px' }} 
      >
        {uploadText}
      </Button>
    </>
  );
};

export default S3Uploader;
