import React, { useState, useEffect } from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import WhatsAppLogin from './whatsappLogin';
import {addAgent} from "../api/agentApi";
const WhatsAppSignup =  ({onSignup, isSignup}) => {
    const [name, setName] = useState('');
    const [agentNameError, setAgentNameError] = useState(false);
    const onWhatsappSubmit = async (otpResponse) => {
        if(otpResponse?.isSuccess){
            // todo incoke agent profile api
            const response = await addAgent({
                phone_number: otpResponse.phone_number,
                name: name
            })
            onSignup(response);
        }
        console.log("whatsapp submit" + otpResponse);
    }
    const onAgentNameBlur = () => {
        setAgentNameError(name.length==0);
    }
    const onAgentNameChange = (value) => {
        setName(value);
        setAgentNameError(value.length==0);
    }
    return (
        <>
            <Grid
                container
                justifyContent="center"
                sx={{ padding: '16px', maxWidth: '400px', margin: 'auto' }}
            >
                <Grid item xs={12}>
                    <TextField
                    required
                    label = "Enter Agent Name"
                    onChange={(e) => onAgentNameChange(e?.target?.value)}
                    placeholder='Enter Agent Name'
                    error={agentNameError}
                    helperText={agentNameError ? "Enter valid agent name" : ""}
                    autoFocus
                    fullWidth
                    onBlur={onAgentNameBlur}
                    />
                </Grid>
            </Grid>
            <WhatsAppLogin propsDisableSubmit={name.length==0} onSubmit={onWhatsappSubmit} isSignup={isSignup}/>
        </>
    )
}
export default WhatsAppSignup;