import { Button, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import { addCandidate } from "../utils/navigateUrls";
import Typeahead from "./typeAhead";
import { searchCandidates } from "../api/candidateApi";
import { prepareDataForAutoCompletion } from "../utils/utils";
import CandidateSearchList from "./candidateSearchList";
// import useAutoCompleteCandidates from "../hooks/useAutoCompleteCandidates";

const AgentDashboard = () => {
    const title = `Welcome to Agent Dashboard`;
    const [candidatesList, setCandidatesList] = useState([]);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [searchCandidatesResult, setSearchCandidatesResult] = useState([]);
    const {agentId: agent_id} = useParams();
    const navigate = useNavigate();

    // useCandidatesList({ agent_id, responseCallback: setCandidatesList });

    const navigateToAddCandidate = () => {
        navigate(addCandidate({agent_id}));
    }

    const onAutoCompleteSelection = (selectedValue) => {
        const ids = selectedValue?.ids ?? [];
        const filteredCandidates = searchCandidatesResult.filter(candidate => ids.includes(candidate.id));
        setCandidatesList(filteredCandidates);
        // const maskPhoneCandidates = filteredCandidates.map(item => {
        //     item.masked_phone_number = item.phone_number = "********" + item.phone_number.slice(-2);
        //     return item;
        // })
        console.log(selectedValue, filteredCandidates);
    }

    const onAutoCompleteChanges = (event) => {
        const value  = event?.target?.value ?? "";
        const fetchSearchCandidates = async({search_text, limit}) => {
            const response = await searchCandidates({search_text, limit});
            const data = response?.data?.candidateSearchResult ?? [];
            setSearchCandidatesResult(data);
            const autoCompleteResults = prepareDataForAutoCompletion(data); 
            console.log(autoCompleteResults);
            setAutoCompleteOptions(autoCompleteResults);
        }
        fetchSearchCandidates({search_text:value, limit: 100});
    }

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center" // Center items vertically
            sx={{ padding: '16px', margin: 'auto' }}
            spacing={2}
        >
            <Grid item sx={{ alignSelf: 'flex-end', marginRight: '16px' }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={navigateToAddCandidate}
                >
                    Add Candidate
                </Button>
            </Grid>
            <Grid item sx={{ alignSelf: 'center', width: '80%', marginTop: '5%' }}>
               <Typeahead 
                    autoCompleteList={autoCompleteOptions}
                    label="Search Candidates by skills/role"
                    onChange={onAutoCompleteChanges}
                    onSelected={onAutoCompleteSelection}
                />
            </Grid>
            <Grid item>
               { candidatesList.length > 0 ? <CandidateSearchList rows={candidatesList}></CandidateSearchList> : null }
            </Grid>
        </Grid>
    );
};

export default AgentDashboard;
