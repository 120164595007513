import { createBrowserRouter } from "react-router-dom";
import WhatsAppLogin from "../component/whatsappLogin";
import WhatsAppSignup from "../component/whatsappSignup";
import AddCandidate from "../component/addCandidate";
import AgentDashboard from "../component/agentDashboard";
import AgentSignup from "../component/agentSignup";
import Container from "../component/landing_page/container";
import PrivacyPolicy from "../component/landing_page/privacyPolicy";
import Pricing from "../component/landing_page/pricing";
import Header from "../component/landing_page/header";
import Heading from "../component/Heading";
import AgentLogin from "../component/agentLogin";


export const router = createBrowserRouter([
{
    path: '/',
    element: <Container></Container>
},
{
    path: '/addAgent',
    element: <><Header/><Heading title="Agent SignUp" variant="h4"/><AgentSignup/></>
},
{
    path: '/agentLogin',
    element: <AgentLogin/>
},
{
    path: 'agent/:agentId/addCandidate',
    element: <AddCandidate />
},
{
    path: 'agent/:agentId/dashboard',
    element:  <><Header/><Heading title="Welcome to Agent Dashboard" variant="h4"/><AgentDashboard/></>
},
{
    path:'/privacyPolicy',
    element: <><Header/><PrivacyPolicy/></>
},
// Todo: Enable after payment integration
// {
//     path:'/pricing',
//     element: <><Header/><Pricing/></>
// },
{
    path: '*',
    element: <Container></Container>
}
]);