import React, {useContext} from "react";
import WhatsAppLogin from "./whatsappLogin";
import Heading from "./Heading";
import Header from "./landing_page/header";
import LoginContext from '../Auth/LoginContext';
import { getAgentDetailsByPhoneNumber } from "../api/agentApi";
import { useNavigate } from "react-router-dom";
import { agentDashboard } from "../utils/navigateUrls";



const AgentLogin = () => {
    const { isLoggedIn, setIsLoggedIn, agentDetails, setAgentDetails } = useContext(LoginContext);
    const navigate = useNavigate();

    const onWhatsappSubmit = async (otpResponse) => {
        console.log("whatsapp submit" + otpResponse);
        // localStorage.setItem('isLoggedIn', otpResponse?.isSuccess);
        setIsLoggedIn(otpResponse?.isSuccess);
        if(otpResponse?.isSuccess){
            const agentResponse = await getAgentDetailsByPhoneNumber({
                phone_number: otpResponse?.phone_number
            });
            const agentDetails = agentResponse?.data;
            console.log(`agentDetails: ${JSON.stringify(agentDetails)}`)
            setAgentDetails(agentDetails);
            navigate(agentDashboard({agent_id: agentDetails?.id}))
        }
    }
    return (
        <>
            <Header/>
            <Heading title="Agent Login" variant="h4"/>
            <WhatsAppLogin onSubmit={onWhatsappSubmit}></WhatsAppLogin>
        </>
    )
}
export default AgentLogin;