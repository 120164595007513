import React, {useContext} from 'react';
import './header.css';
import { useNavigate } from 'react-router-dom';
import { agentSignupUrl, agentLoginUrl, privacyPolicyUrl, pricingUrl, agentDashboard } from "../../utils/navigateUrls";
import FeaturesSvg from './features_svg';
import useRouteState from '../../hooks/useRouteState';
import Logo from './logo';

import LoginContext from '../../Auth/LoginContext';


const Header = () => {

    const getClassForRoute = useRouteState();
    // const [isLoggedIn, setIsLoggedIn] = useLoginAuth('isLoggedIn', false);
    const { isLoggedIn, setIsLoggedIn, agentDetails } = useContext(LoginContext);

    const navigate = useNavigate();

    const agentSignUp = () => {
        navigate(agentSignupUrl());
    }
    const agentLogin = () => {
        navigate(agentLoginUrl());
    }

    const privacyPolicy = (e) => {
        e.preventDefault();
        navigate(privacyPolicyUrl())
    }

    const pricingPage = (e) => {
        e.preventDefault();
        navigate(pricingUrl())
    }

    const navigateToDashboard = (e) => {
        e.preventDefault();
        navigate(agentDashboard({agent_id: agentDetails?.id}))
    }

    // const getClassForRoute = (match) => {
    //     if (currentRoute === match) {
    //       return "nav-link px-2 text-secondary";
    //     } else {
    //       return "nav-link px-2 text-secondary text-white";
    //     }
    // };

    const logOut = (e) => {
        e.preventDefault();
        setIsLoggedIn(false);
    }

    const LoginToggleView = () => {
        return (
            <div>
                {isLoggedIn ? (
                    <div className="dropdown text-end">
                        <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle"/>
                        </a>
                        <ul className="dropdown-menu text-small">
                            {/* <li><a className="dropdown-item" href="#">New project...</a></li> */}
                            {/* <li><a className="dropdown-item" href="#">Settings</a></li> */}
                            <li><a className="dropdown-item" href="#" onClick={navigateToDashboard}>Dashboard</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#" onClick={logOut}>Sign out</a></li>
                        </ul>
                    </div>
                ) : (
                    <div className="text-end">
                        <button type="button" className="btn btn-outline-light me-2" onClick={agentLogin}>Agent Login</button>
                        <button type="button" className="btn btn-warning" onClick={agentSignUp}>Agent Sign-up</button>
                    </div>
                )}
            </div>
        )
    }
    
    return (
        <main>
           
            <FeaturesSvg/>
            <header className="p-3 text-bg-dark">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                            {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlinkHref="#bootstrap"></use></svg> */}
                            {/* <img src="download.png" alt="SVG Image" width="40" height="32"/> */}
                            {/* Career Eazy */}
                            <Logo/>
                            {/* <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button> */}
                        </a>

                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li><a href="/" className={getClassForRoute('/')}>Home</a></li>
                            {/* <li><a href="#" onClick={pricingPage} className={getClassForRoute('/pricing')}>Pricing</a></li> */}
                            <li><a href="#" onClick={privacyPolicy} className={getClassForRoute('/privacyPolicy')}>Privacy Policy</a></li>
                        </ul>

                        <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                            {/* Add your form elements here */}
                        </form>
                        <LoginToggleView/>
                    </div>
                </div>
            </header>
                
        </main>
    );
}

export default Header;
